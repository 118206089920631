<template>
  <div class="">
    <div class="w-screen h-screen">
      <Player
        v-if="!isEmpty(podcastInfo)"
        :fileKey="fileKey"
        :fileOwner="fileOwner"
        :loading="loading"
        :customClickAction="true"
        :showVideoPlayer="true"
        :showSeeker="debugging"
        :subtitleStyle="podcastInfo.hasOwnProperty('subtitleStyle') ? podcastInfo.subtitleStyle : null"
        :subtitleOverRide="subtitleOverRide"
        :disableInteraction="fullscreen"
        :scale="getScale"
        :fullscreen="fullscreen"
        :playerType="playerType"
        :enableLogs="enableLogs"
        :videoPlayerInfo="videoPlayerInfo"
        @onTogglePlay="toggleState"
        @goToTime="goToTime"
      />
    </div>

    <DocPreview
      v-show="false"
      ref="clipDoc"
      :fileKey="fileKey"
      :fileOwner="fileOwner"
      :loading="loading"
      :height="'320px'"
      :fullscreen="fullscreen"
      :playerType="playerType"
      @onLoadingChange="onLoadingChange"
    />
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import { mapGetters, mapActions } from 'vuex'
import { getImageFromPath } from '@/components/VideoPlayer/playerElementsData'
import Player from './PlayerWrapper.vue'
import DocPreview from './DocPreview.vue'

export default {
  name: 'SpextPlayer',
  data() {
    return {
      isEmpty,
      loading: 'false',
      fileKey: this.$route.params.fileId,
      fileOwner: this.$route.params.userId,
      // fullscreen: true
      debugging: this.$route.query.debug === 'true',
      fullscreen: this.$route.query.debug !== 'true',
      aspectRatio: this.$route.query.aspectRatio || '',
      template: this.$route.query.template || '',
      enableLogs: this.$route.query.logs === 'true'
    }
  },
  components: {
    Player,
    DocPreview
  },
  computed: {
    ...mapGetters({
      podcastInfo: 'editor/podcastInfo'
    }),
    videoPlayerInfo () {
      const {
        backgroundImage,
        coverArt,
        subtitleStyle,
        enableSubtitleFormatting,
        heading,
        aspectRatio,
        template,
        accentColor,
        backgroundType,
        backgroundColor,
        logo,
        titleStyle,
        enableLogo
      } = { ...this.podcastInfo }
      const info = {
        backgroundImage,
        coverArt,
        subtitleStyle,
        enableSubtitleFormatting,
        heading,
        aspectRatio: this.aspectRatio || aspectRatio,
        template: this.template || template,
        accentColor,
        backgroundType,
        backgroundColor,
        logo,
        titleStyle,
        enableLogo
      }

      return info
    },
    subtitleOverRide: function() {
      const { subtitle } = { ...this.podcastInfo }
      return subtitle
    },
    playerType() {
      return this.podcastInfo && this.podcastInfo.hasOwnProperty('playerType') ? this.podcastInfo.playerType : 'video'
    },
    getScale: function() {
      return window.innerWidth / 1920
    }
  },
  methods: {
    ...mapActions({
      toggleState: 'editor/toggleState'
    }),
    onLoadingChange: function(loading) {
      this.loading = loading
    },
    goToTime: function(time) {
      this.$refs.clipDoc.goToTime(time)
    }
  },
  mounted() {
    if (window.Intercom) {
      window.Intercom('update', {
        'hide_default_launcher': true
      })
    }
    document._spext_currentTime = 0
  },
  watch: {
    videoPlayerInfo: function(val) {
      const { backgroundImage, coverArt } = { ...val }
      if (backgroundImage && backgroundImage.url) getImageFromPath(backgroundImage.url)
      if (coverArt && coverArt.url) getImageFromPath(coverArt.url)
    }
  }
}
</script>

<style scoped lang="scss">
.spext-player {
  height: 400px
}

</style>
