<template>
  <div ref="previewPlayer" class="relative flex flex-col items-center justify-center w-full h-full bg-black group">
    <div v-if="!fullscreen && !isReady" class="absolute top-0 z-50 flex items-center justify-center w-full h-full loadingOverlay">
      <CircularLoader
        class="absolute flex items-center justify-center text-white rounded-full h-14 text-red w-14"
      />
      <div class="text-xs font-semibold text-white font-h">{{Math.round(docProgress)}}%</div>
    </div>

    <div v-else-if="!disableInteraction" class="absolute top-0 z-50 flex items-center justify-center w-full h-full">
      <div
        v-if="isPlaying"
        class="flex items-center justify-center hidden text-white rounded-full cursor-pointer bg-red h-14 w-14 group-hover:flex"
        @click="togglePlay"
      >
        <PauseIcon />
      </div>
      <div
        v-else
        class="flex items-center justify-center text-white rounded-full cursor-pointer bg-red h-14 w-14"
        @click="togglePlay"
      >
        <PlayIcon />
      </div>

      <div v-show="isReady && showSeeker" class="absolute w-full px-4 bottom-2">
        <VueSlider
          id="playerSeeker"
          key="playerSeeker"
          v-model="progressOutOf100"
          style="height:2px;padding: 0;width: 100%;"
          :railStyle="{'cursor': 'pointer', 'background-color': '#666C7C'}"
          :processStyle="{'background-color': '#fff'}"
          :tooltip="'none'"
          :interval="0.1"
        >
          <template v-slot:dot="">
            <div :class="'custom-dot'" />
          </template>
        </VueSlider>
      </div>
    </div>

    <VideoPlayer
      v-if="showVideoPlayer"
      id="fullscreen-ce-player"
      :type="playerType"
      :subtitle="subtitleOverRide || subtitle"
      :autoplay="fullscreen"
      :loading="!mediaReady"
      :subtitleStyle="subtitleStyle"
      :scale="scale"
      :videoPlayerInfo="videoPlayerInfo"
      :showThumbnail="!fullscreen"
      :vpRatio="vpRatio"
      :progress="progressOutOf100"
      :enableLogs="enableLogs"
      :playerMode="true"
      @goToTime="goToTime"
    />
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import store from '@/services/store'
import { mapGetters, mapActions } from 'vuex'
import { formatAsSubtitleNew } from '@/utilities/utility'
import { myProseEditor } from '@/view/voiceEditor/proseEditor/util/utility'

import VideoPlayer from '@/components/VideoPlayer/index.vue'
import PlayIcon from '@/components/base/icons/Play.vue'
import PauseIcon from '@/components/base/icons/Pause.vue'
import CircularLoader from '@/components/base/icons/CircularLoader.vue'

export default {
  props: {
    fileKey: {
      type: String,
      required: true
    },
    fileOwner: {
      type: String,
      required: true
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    from: {
      type: Number,
      default: 0
    },
    to: {
      type: Number,
      default: 99999
    },
    loading: {
      type: String,
      required: true
    },
    subtitleStyle: {
      type: Object,
      required: false
    },
    showSeeker: {
      type: Boolean,
      default: true
    },
    customClickAction: {
      type: Boolean,
      default: false
    },
    playerType: {
      type: String,
      default: 'video'
    },
    videoPlayerInfo: {
      type: Object,
      default: () => ({})
    },
    showVideoPlayer: {
      type: Boolean,
      default: false
    },
    subtitleOverRide: {
      type: Array,
      required: false
    },
    disableInteraction: {
      type: Boolean,
      default: false
    },
    showplayer: {
      type: Boolean,
      default: true
    },
    scale: {
      type: Number,
      default: 0.2111
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    enableLogs: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VideoPlayer,
    CircularLoader,
    PlayIcon,
    PauseIcon,
    VueSlider
  },
  data: function() {
    return {
      height: 228,
      width: 405,
      isReady: false,
      subtitle: []
    }
  },
  computed: {
    ...mapGetters({
      files: 'app/computedList',
      isPlaying: 'editor/isPlaying',
      currentTime: 'doc/currentTime',
      totalTime: 'editor/totalTime',
      videoReady: 'video/sourcesReady',
      audioReady: 'editor/sourcesReady',
      user: 'app/user',
      docEndedAt: 'editor/docEndedAt',
      docProgress: 'editor/docProgress'
    }),
    vpRatio: function() {
      return this.width / this.height
    },
    mediaReady: function() {
      return this.loading !== 'true' && this.audioReady && this.videoReady
    },
    progressOutOf100: {
      get() {
        const max = Math.min(this.totalTime, this.to)
        const min = Math.max(this.from, 0)
        const range = max - min
        if (range) {
          if (this.currentTime >= max) return 100
          return ((this.currentTime - min) / range) * 100
        }
        return 0
      },
      set(newVal) {
        const max = Math.min(this.totalTime, this.to)
        const min = Math.max(this.from, 0)
        const range = max - min
        if (range) {
          const seekedTime = min + ((range * newVal) / 100)
          this.goToTime(seekedTime)
        }
      }
    }
  },
  methods: {
    ...mapActions({
      toggleState: 'editor/toggleState'
    }),
    togglePlay: function() {
      if (this.customClickAction) this.$emit('onTogglePlay')
      else this.toggleState(true)
    },
    goToTime: function(time) {
      this.$emit('goToTime', time)
    },
    syncSubtitle: function() {
      if (myProseEditor) this.subtitle = formatAsSubtitleNew(myProseEditor.view.state)
    },
    getWindowWidth: function() {
      this.height = this.$refs.previewPlayer.clientHeight
      this.width = this.$refs.previewPlayer.clientWidth
    }
  },
  watch: {
    loading: function(val, oldVal) {
      if (oldVal === 'true' && val === 'false') this.syncSubtitle()
    },
    mediaReady: function(val, oldVal) {
      if (!val && oldVal) this.isReady = false
      if (!oldVal && val) {
        setTimeout(() => {
          this.isReady = true
          this.$emit('onPlayerReady')
        }, 2000)
      }
    },
    currentTime: function(val) {
      if (val >= this.to) {
        if (this.isPlaying) this.toggleState(true)
        this.$emit('onPreviewEnd')
      }
    },
    showplayer: function(val) {
      if (val) this.getWindowWidth()
    }
  },
  mounted() {
    this.getWindowWidth()
    window.addEventListener('resize', this.getWindowWidth)
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.getWindowWidth)
    store.commit('video/setSourcesReady', true)
    store.commit('editor/setSourcesReady', false)
  }
}
</script>

<style lang="scss" scoped>
.loadingOverlay {
  background: rgba(229, 231, 235, 0.8);
  backdrop-filter: blur(7px);
}

.custom-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0px 0px 14px -3.5px rgba(0, 0, 0, 0.61);
  background-color: #fff;
  transition: all .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top:2px;
  left: 7px;
}

.custom-dot::before {
  content: '';
  display: inline-block;

  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #E2462C;
}
</style>
